import React from "react"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt,
        faPhone,
        faCommentAlt } from "@fortawesome/free-solid-svg-icons"

import ModalButton from "../../components/NewsletterModal/ModalButton"

import styles from "./connect.module.css"

const ContactInfo = () => (
  <>
    <Row>
        <Col>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: '1rem'
          }}
        >
            <FontAwesomeIcon
            icon={faMapMarkerAlt}
            aria-hidden="true"
            color="#003366"
            size="2x"
            style={{
                marginRight: "10px",
                marginTop: '-20px',
                width: '5%'
            }}

          />

            <p className={styles.contactInfo}><em>Soon to Be:</em><br/> Corner of 5th &amp; Little Ave Driggs ID 83422</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: '1rem'
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            aria-hidden="true"
            color="#003366"
            size="2x"
            style={{
              marginRight: "10px",
              marginTop: "-15px",
              width: '5%'
            }}
          />
          <p className={styles.contactInfo}><a href="tel:208-607-8975">208-607-8975</a></p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: '1rem'
          }}
        >
          <FontAwesomeIcon
            icon={faCommentAlt}
            aria-hidden="true"
            color="#003366"
            size="2x"
            style={{
              marginRight: "10px",
              marginTop: "-15px",
              width: '5%'
            }}
          />
          <p className={styles.contactInfo}><span style={{
              textTransform: 'uppercase'
          }}>Stay in the loop!</span><br/>
          Sign up for our newsletter and updates</p>
        </div>
        <div>
        <ModalButton
            signUpText="Sign Up!"
            style={styles.newsletterButton}
            
        />
        </div>
      </Col>
    </Row>
  </>
)

export default ContactInfo
