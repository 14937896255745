import React from "react"
import { Spinner, Form, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"

import styles from "../../components/Connect/connect.module.css"

// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, " *Names must have at least 2 characters")
    .max(100, " *Names can't be longer than 100 characters")
    .required(" *First Name is required"),
lastName: Yup.string()
    .min(2, " *Names must have at least 2 characters")
    .max(100, " *Names can't be longer than 100 characters")
    .required(" *Last Name is required"),
  email: Yup.string()
    .email(" *Must be a valid email address")
    .max(100, " *Email must be less than 100 characters")
    .required(" *Email is required"),
  message: Yup.string()
    .min(2, "*Message must be more than 2 characters")
    .max(1000, "*Message cannot be more than 1,000 characters")
    .required("*Message is required"),
})

const ContactForm = () => {
  return (
    <div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          fetch(
            "https://duw1iwb8sb.execute-api.us-west-2.amazonaws.com/production/contact-teton-valley-aquatics",
            {
              headers: { "Content-Type": "application/json; charset=utf-8" },
              method: "POST",
              body: JSON.stringify(values),
            }
          ).then(response => {
            if (response.status === 200) {
              actions.setStatus({
                success: "Message Sent",
              })
            } else {
              actions.setStatus({
                fail: "Network Error",
              })
            }
          })

          setTimeout(() => {
            console.log(values)
            actions.setSubmitting(false)
            actions.resetForm()
          }, 400)
        }}
      >
        {({
          status,
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {console.log(values)}
            <Form.Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>
                    First Name{" "}
                    { touched.firstName && errors.firstName ? (
                      <div className={styles.errorMessage}>{errors.firstName}</div>
                    ) : null }
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    className={
                      touched.firstName && errors.firstName ? "error" : null
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                    <Form.Label>
                        Last Name{" "}
                        {touched.lastName && errors.lastName ? (
                            <div className={styles.errorMessage}>{errors.lastName}</div>
                        ) : null }
                    </Form.Label>
                    <Form.Control 
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        className={
                          touched.lastName && errors.lastName ? "error" : null
                        }
                    />
                </Form.Group>
              </Col>
              </Form.Row>
              <Form.Row>
              <Col sm={12}>
              <Form.Group>
              <Form.Label>
                Email{" "}
                {touched.email && errors.email ? (
                  <div className={styles.errorMessage}>{errors.email}</div>
                ) : null}
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={touched.email && errors.email ? "error" : null}
              />
            </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
                <Col sm={12}>
                <Form.Group>
              <Form.Label>
                Your Message{" "}
                {touched.message && errors.message ? (
                  <div className={styles.errorMessage}>{errors.message}</div>
                ) : null}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                className={
                  touched.message && errors.message ? "error" : null
                }
              />
            </Form.Group>
                </Col>
            </Form.Row>
            <Button
                variant="primary"
                className={`${styles.sendButton} btn btn-action`}
                type="submit"
                disabled={isSubmitting}
            >
                { status && status.success ? "Message Sent!"
                : isSubmitting ? <div>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                            marginRight: '10px',
                        }}
                    /> "Sending..."</div>
                        : "Send"
                }
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactForm
