import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "../components/Connect/connect.module.css"

import ContactForm from "../components/ContactForm/ContactForm"
import ContactInfo from "../components/Connect/ContactInfo"

import HomeBlogBlock from "../components/HomeBlogBlock/HomeBlogBlock"

const ConnectPage = () => (
    <Layout>
    <SEO title="Connect with Teton Valley Aquatics" />
        <Container style={{
            marginTop: '4rem'
        }}>
            <Row className="my-4">
            <Col sm={6}>
                <h1 className={styles.contactTitle}>Contact Info</h1>
                <ContactInfo />
                </Col>
                <Col sm={6}>
                    <h1 className={styles.contactTitle}>Send Us A Message</h1>
                    <ContactForm />
                </Col>
                
            </Row>
            <hr style={{
                marginTop: '4rem',
                paddingBottom: '2rem' }}/>
            <Row className="my-4">
                <Col>
                    <h1 className={styles.contactTitle} style={{textAlign: 'center'}}>Teton Valley Aquatics Making A Difference</h1>
                </Col>
            </Row>
            <Row className="my-4">
                <Col>
                    <HomeBlogBlock />
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default ConnectPage
