import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './HomeBlogBlock.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import SideStories from "./SideStories"
 
const getBlogPosts = graphql`
query {
    allContentfulBlogPost (
      sort: { fields: [updatedAt], order: DESC }
      limit: 1
      ) {
      totalCount
      edges {
        node {
          slug
          title
          updatedAt(formatString: "dddd MMMM Do, YYYY")
          postExcerpt
          image: featuredImage {
            fluid(maxWidth: 600) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

const HomeBlogBlock = () => {
    return (
      <StaticQuery
        query={ getBlogPosts }
        render={ data => {

          const posts = data.allContentfulBlogPost.edges
          return (
            posts.map(({ node }) => {
              return (
            <Container>
                <Row>
                    <Col sm={6}>
                        <div style={{
                            width: '100%'
                        }}>
                            <img className={`${styles.featuredStoryImage} ${styles.rounded}`} src={node.image?.fluid.src} alt={node.image?.title} />
                        </div>
                        <div>
                          <Link to={`/news/${node.slug}`}><h2 className={styles.postTitle}>{node.title}</h2></Link>
                        </div>
                        <div>
                          <p>{node.updatedAt}</p>
                          <p>{node.postExcerpt}</p>
                        </div>
                        <div>
                          <p><Link to={`/news/${node.slug}`} className={styles.readMore}>Read More <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" /></Link></p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        
                         <SideStories />
                        
                    </Col>
                </Row>
            </Container>
              )
            })
          )
        }}
        >
      </StaticQuery>
    )
}

export default HomeBlogBlock;