import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import styles from './HomeBlogBlock.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
 
const getBlogPosts = graphql`
query  {
    allContentfulBlogPost (
      sort: { fields: [updatedAt], order: DESC }
      limit: 3
      skip: 1
      ) {
      totalCount
      edges {
        node {
          slug
          title
          updatedAt(formatString: "dddd MMMM Do, YYYY")
          postExcerpt
          image: featuredImage {
            fluid(maxWidth: 200) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

const SideStories = () => {
    return (
      <StaticQuery
        query={ getBlogPosts }
        render={ data => {

          const posts = data.allContentfulBlogPost.edges
          return (
            posts.map(({ node }) => {
              return (
                  <>
                        <Row>
                            <Col sm={4}>

                            <img className={`${styles.featuredStoryImage} ${styles.rounded}`} src={node.image?.fluid.src} alt={node.image?.title} />

                            </Col>
                            <Col sm={8}>
                            <div>
                          <Link to={`/news/${node.slug}`}><h2 className={styles.postTitle}>{node.title}</h2></Link>
                        </div>
                        <div>
                          <p>{node.updatedAt}</p>
                        </div>
                        <div>
                          <p><Link to={`/news/${node.slug}`} className={styles.readMore}>Read More <FontAwesomeIcon icon={faAngleRight} aria-hidden="true" /></Link></p>
                        </div>
                            </Col>
                        </Row>
                        <div>
                            <hr />
                        </div>
                    </>

              )
            })
          )
        }}
        >
      </StaticQuery>
    )
}

export default SideStories;